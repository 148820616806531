<template>
  <div class="accordion-item" :id="'myAccordion-' + props.item.id">    
      <button type="button" class="accordion-button rounded-0 collapsed" aria-expanded="false" data-bs-toggle="collapse"
        :data-bs-target="'#collapse-' + props.item.id" @click="toggleAccordion">
        <h3 class="item-header">{{ props.item.heading }}</h3>
        <span v-if="isCollapsed" class="material-icons-sharp material-icons-sharp-plus-minus-buttons material-icons-sharp-add-cirle" role="img" :aria-label=texts.questionBlockItem.oppnaSvar></span>
        <span v-else class="material-icons-sharp material-icons-sharp-plus-minus-buttons material-icons-sharp-do-not-disturb-on" role="img" :aria-label=texts.questionBlockItem.doljaSvar></span>
      </button>   
    <div :id="'collapse-' + props.item.id" class="queaccordionstionblock-collapse collapse"
      :data-bs-parent="'#myAccordion-' + props.item.id">
      <div class="card-body">
        <div class="accordion-preamble" v-html="props.item.bodytext"></div>
      </div>
    </div>
  </div>
</template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import { QuestionItem } from "../../types";
  
  export interface Props {
    item: QuestionItem;
  }
  const texts = ref<any>(uhr.localizedTexts);
  const props = defineProps<Props>();
  const isCollapsed = ref(true);
  
  const toggleAccordion = () => {
    isCollapsed.value = !isCollapsed.value;
  };
  </script>
  