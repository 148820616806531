<template>
    <h2>Konton</h2>
    <div class="users" v-for="(person, index) in users" :key="index">
        <div class="medarbetar-info-ruta">
          <p>{{ texts.accounts.name }}: {{ person.fullName }}</p>
          <p>{{ texts.accounts.email }}: {{ person.email }}</p>
          <p>{{ texts.accounts.org }}: {{ person.organisation }}</p>
        </div>
      </div>
  </template>
  
  <script setup lang="ts">
  import { KontoUser } from "../../types";
  import api from "../../services/SHServices";
  import { onMounted, ref,watch } from "vue";
  import { filterStore } from './FilterStore';
  
  const texts = ref<any>(uhr.localizedTexts);
  const users = ref<KontoUser[]>([]);
  
  onMounted(async () => {
    fetchAllUsers(); // start with no filter
  });
  
  const fetchAllUsers = () => {
    api.getMemberUsers(filterStore.queryString)
      .then(r => {
        users.value = r;
      });
  };
  
  watch(filterStore, () => {
    fetchAllUsers ();
  })
  
  </script>
