import "bootstrap";
import { createApp } from "vue";
import { createWebHashHistory, createRouter } from 'vue-router';

import "./global";
import "./types";
import { applyGlobalAnchorStyles }  from "./utils";

import "./styles/main.scss";
import NavBlock from "./components/nav-block/NavBlock.vue";
import FilterBlock from "./components/filter-block/Filterblock.vue";
import EventNavBlock from "./components/event/EventReleatedlistBlock.vue";
import EventSearch from "./components/event/EventSearch.vue";
import Search from "./components/search/Search.vue";
import MiniSearch from "./components/search/MiniSearch.vue";
import AccordionBlock from "./components/accordion-block/AccordionBlock.vue";
import Video from "./components/Video.vue";
import UserEdit from './components/kontohantering/UserEdit.vue';
import UserFilter from './components/kontohantering/UserFilter.vue';
import UserList from './components/kontohantering/UserList.vue';
import Topic from './components/yaf/Topic.vue';
import TopicList from "./components/yaf/TopicList.vue";
import Forum from "./components/yaf/Forum.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';

//se till så den kopieras till assets vid bygge. behövs inte om bilden används från frontend
import "./images/logo.png";
import CreateMessage  from "./components/yaf/CreateMessage.vue";
import EditMessage  from "./components/yaf/EditMessage.vue";


declare global {
	var uhr: UhrGlobal;

  var klaro: {getManager: (config?: any) => KlaroManager;}

  var klaroConfig: any; 

}

document.addEventListener("scroll", function () {
	const button = document.querySelector(".sticky-button");
	var footerHeight: number;

	if (button !== null) {
		button.classList.remove("nosticky");
		if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
			footerHeight =
				document.getElementById("footer-links")?.clientHeight! + 16; //Place 16px abouve purple footerarea

			if (!Number.isNaN(footerHeight)) {
				button.setAttribute("style", "bottom:" + footerHeight + "px;");
			}
		} else {
			button.setAttribute("style", "");
		}
	}
});

function domReady(cb: Function): void {
	if (
		document.readyState === "complete" ||
		document.readyState === "interactive"
	) {
		cb();
	} else {
		document.addEventListener("DOMContentLoaded", () => {
			cb();
		});
	}
}

const bindSiteEvents = () => {
	var searchButton = document.getElementById("toggle-search-button");
	var menuButton = document.getElementById("toggle-menu-button");
	var skiplinksContainer = document.getElementById("skip-links");

	if (skiplinksContainer) {
		const innerLink = skiplinksContainer.querySelector(".btn-outline-primary");
		const gotocontent = skiplinksContainer.querySelector("#gotocontent");
		const contentDiv = document.getElementById("main-content");

		const allElements = document.querySelectorAll(
			"a:not(.btn-outline-primary)"
		);

		innerLink?.addEventListener("focus", () => {
			if (skiplinksContainer) skiplinksContainer.classList.add("focused");
		});

		allElements.forEach((div) => {
			div?.addEventListener("focus", () => {
				removeFocus();
			});
		});

		document.addEventListener("click", (event) => {
			const elementClicked = event.target as HTMLElement;

			if (!elementClicked.classList.contains("btn-outline-primary")) {
				removeFocus();
			}
		});

		gotocontent?.addEventListener("click", (event) => {
			handleContentOrNavActions(contentDiv);
			event.preventDefault();
		});

		gotocontent?.addEventListener("keydown", (event) => {
			const keyboardEvent = event as KeyboardEvent;
			if (keyboardEvent.key === "Enter") {
				handleContentOrNavActions(contentDiv);
				event.preventDefault();
			}
		});

		function handleContentOrNavActions(el: HTMLElement | null) {
			removeFocus();
			scrollIntoViewAndFocus(el);
		}

		function removeFocus() {
			if (skiplinksContainer) skiplinksContainer.classList.remove("focused");
		}
	}

	if (searchButton != null) {
		searchButton.onclick = () => {
			document.body.classList.toggle("show-search");
			document.getElementById("focus-search")?.focus();
			searchButton?.setAttribute(
				"aria-expanded",
				`${!(searchButton.getAttribute("aria-expanded") === "true")}`
			);
			// Close menu
			document.getElementById("navbarToggle")?.classList.remove("show");
			menuButton?.classList.add("collapsed");
			menuButton?.setAttribute("aria-expanded", "false");
			overlay.hide();
		};
	} else {
		console.warn("could not find #toggle-search-button");
	}

	if (menuButton != null) {
		menuButton.onclick = () => {
			document.body.classList.remove("show-search");
			searchButton?.setAttribute("aria-expanded", "false");
		};
	} else {
		console.warn("could not find #toggle-search-button");
	}

	const overlay = {
		show: () => {
			document.body.classList.remove("transition-overlay");
			document.body.classList.add("show-overlay");
		},
		hide: () => {
			document.body.classList.remove("transition-overlay");
			document.body.classList.remove("show-overlay");
		},
		transiton: () => {
			document.body.classList.add("transition-overlay");
			document.body.classList.remove("show-overlay");
		},
	};
	const isTargetEvent = (ev: Event) => ev.eventPhase === Event.AT_TARGET;
	const togglers = [
		...document.getElementsByClassName("toggle-overlay-on-collapse"),
	];
	togglers.forEach((element) => {
		["hide.bs.collapse", "show.bs.collapse"].forEach((event) =>
			element.addEventListener(event, (ev) => {
				if (isTargetEvent(ev)) {
					overlay.transiton();
				}
			})
		);
		element.addEventListener("hidden.bs.collapse", (ev) => {
			if (isTargetEvent(ev)) {
				overlay.hide();
			}
		});
		element.addEventListener("shown.bs.collapse", (ev) => {
			if (isTargetEvent(ev)) {
				overlay.show();
			}
		});
	});
};

domReady(() => {
	applyGlobalAnchorStyles();
	bindSiteEvents();

	if (document.body.scrollHeight <= document.body.clientHeight) {
		const button = document.querySelector(".sticky-button");
		if (button !== null) {
			button.classList.add("nosticky");
		}
	}

	setupKlaroTabb();

	//Observer, view-change Klaro Div
	const klaroDiv: HTMLElement | null = document.getElementById("klaro");
	if (klaroDiv) {
		const cookieModalElements = klaroDiv.querySelectorAll(".cookie-modal");

		if (cookieModalElements.length > 0) {
			const observer = new MutationObserver((mutationsList) => {
				for (const mutation of mutationsList) {
					if (mutation.type === "childList") {
						setupKlaroTabb();
					}
				}
			});
			const config: MutationObserverInit = {
				attributes: true,
				childList: true,
				subtree: true,
			};
			observer.observe(klaroDiv, config);
		}
	}

	var VuecomponentList = [
		{ container: "nav-block", component: NavBlock },
		{ container: "event-releatedlist-block", component: EventNavBlock },
		{ container: "filter-block", component: FilterBlock },
		{ container: "search", component: Search },
		{ container: "accordion-block", component: AccordionBlock },
		{ container: "mini-search", component: MiniSearch },
		{ container: "event-search", component: EventSearch },
		{ container: "video-component", component: Video },
		{ container: "user-edit", component: UserEdit },
		{ container: "user-filter", component: UserFilter },
		{ container: "user-list", component: UserList},
		{ container: 'topic', component: Topic },
		{ container: 'create_message', component: CreateMessage},
		{ container: 'edit_message', component: EditMessage},
		{ container: 'forum', component: Forum, routes: [
			{path: '/:page?', component: TopicList, name: "forumroot" },
			{path: '/topic/:topicid/:page?', component: Topic, props: true  }
			]
		}
	];

	VuecomponentList.forEach((item) => {
		initVueComponent(item);
	});

	function initVueComponent(item: any) {
		var pagebottomTAG = item.container,
			pagebottomDOMs = document.querySelectorAll(pagebottomTAG);
		pagebottomDOMs.forEach((pagebottomDOM) => {
			if (pagebottomDOM) {
				var rawoptions = pagebottomDOM.getAttribute("data-settings"),
					options = {};
				if (rawoptions) {
					options = JSON.parse(rawoptions);
				}

				const app = createApp(item.component, { ...options }).use( CKEditor );

				if(item.routes){
					const router = createRouter({
						history: createWebHashHistory(),
						routes: item.routes,
					});
					app.use(router);
				} 

				if (pagebottomTAG.startsWith(".")) {
					app.mount("#" + pagebottomDOM);
				} else {
					app.mount(pagebottomDOM);
				}
			}
		});
	}
});

function scrollIntoViewAndFocus(el: HTMLElement | null) {
	el?.scrollIntoView({ behavior: "smooth", block: "start" });
	setTimeout(() => {
		el?.focus();
	}, 300); // Adjust the timeout value as needed
}

function setupKlaroTabb() {
	const cookieModal = document.querySelector(".cookie-modal") as HTMLElement;

	if (cookieModal) {
		// Select the element
		const labelElement = document.querySelector(
			".cm-list-label"
		) as HTMLElement;

		// Check if the element exists and remove the tabindex attribute
		if (labelElement) {
			labelElement.removeAttribute("tabindex");
		}

		const focusableElements = Array.from<HTMLElement>(
			cookieModal.querySelectorAll(
				'button:not([disabled]), [href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
			)
		);

		let currentIndex = 0;

		const firstFocusable = focusableElements[0];
		const lastFocusable = focusableElements[focusableElements.length - 1];

		// Set initial focus on the first focusable element
		firstFocusable.focus();

		cookieModal.addEventListener("keydown", (event) => {
			if (event.key === "Tab") {
				// Prevent default tab behavior
				event.preventDefault();

				const activeElement = document.activeElement as HTMLElement;

				if (event.shiftKey) {
					// Shift + Tab
					if (activeElement === firstFocusable) {
						// Move focus to the last focusable element within modal
						lastFocusable.focus();
						currentIndex = focusableElements.length - 1;
					} else {
						// Move focus to the previous focusable element
						currentIndex =
							(currentIndex - 1 + focusableElements.length) %
							focusableElements.length;
						focusableElements[currentIndex].focus();
					}
				} else {
					// Tab
					if (currentIndex + 1 === focusableElements.length) {
						// Move focus to the first focusable element within modal
						firstFocusable.focus();
						currentIndex = 0;
					} else {
						// Move focus to the next focusable element
						currentIndex = (currentIndex + 1) % focusableElements.length;
						focusableElements[currentIndex].focus();
					}
				}
			}
		});
	}
}
