<template>
    <div class="search-input">
        <label :for=props.uniqueID class="visually-hidden">{{ texts.searchField.sok }}</label>
        <div class="input-group">
            <input type="text" class="form-control" 
                :id="props.uniqueID"
                v-model="value"
                :placeholder="props.placeholder"
                @keyup.enter="search"
                @keyup="emitKeyUp"
                >
            <button @click="search"><span class="icon"></span> {{ texts.searchField.sok }} </button>
        </div>
    </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'
  const texts = ref<any>(uhr.localizedTexts);
  const props = defineProps({
    placeholder: {
        type: String,
        default: 'Sök'
    },
    uniqueID: {
      type:String,
      default: 'search-id-default'
    },
    modelValue: String
  });
  
  const emit = defineEmits(['searchClick','keyup', 'update:modelValue']);

  const value = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emit('update:modelValue', val)
    }
  });


  const search = () => {
    emit('searchClick',value.value);
  };
  const emitKeyUp = () => {
    emit('keyup',value.value);
  }
</script>