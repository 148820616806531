<template>
  <h2>Konton</h2>
  <div class="accordion" v-for="(person, index) in users" :key="index">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button accordion-button-users collapsed" type="button" data-bs-toggle="collapse"
          :data-bs-target="`#accordion-info-${index}`" aria-expanded="false" :aria-controls="`accordion-info-${index}`">
          <span :class="`status ${person.state}`">{{ person.state }}</span>
          {{ person.fullName }} - {{ person.email }} 

        </button>
      </h2>
      <div :id="`accordion-info-${index}`" class="accordion-collapse accordion-collapse-users collapse">
        <div class="accordion-body">
          <p>{{ texts.accounts.name }}: {{ person.fullName }}</p>
          <p>{{ texts.accounts.email }}: {{ person.email }}</p>
          <p>{{ texts.accounts.org }}: {{ person.organisation }}</p>
          <p>{{ texts.accounts.role }}: {{ person.roleInOrganisation }}</p>
          <p>{{ texts.accounts.motivation }}: {{ person.motivering }}</p>
          <p>{{ texts.accounts.handledby }}: {{ person.handledBy }}</p>
          <p>{{ texts.accounts.requestSent }}: {{ person.requestSent }}</p>
          <p>{{ texts.accounts.requestUpdated }}: {{ person.requestUpdated }}</p>

          <button @click="approveUser(person)" class="btn btn-outline-primary"
            :class="{ hidden: checkState(person.state) }">{{ texts.accounts.approveuser }}</button>
          <button class="btn btn-outline-secondary" @click="showModal = true">{{ texts.accounts.denyuser }}</button>

          <!-- Modal -->
          <div v-if="showModal" class="modal-reject"
            @keydown.tab.prevent="$event.shiftKey || !checkIfLastTabElement($event) || focusFirstElement()">
            <div class="modal-content">
              <p>{{ texts.accounts.modalrejectmessage }}</p>
              <button @click="confirmRejectUser(person)" class="btn btn-outline-primary confirm">{{
                texts.accounts.modalrejectconfirm }}</button>
              <button @click="cancelRejectUser" class="btn btn-outline-secondary">{{ texts.accounts.modalrejectcancel
              }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { KontoUser } from "../../types";
import api from "../../services/SHServices";
import { onMounted, ref,watch } from "vue";
import { focusFirstElement, checkIfLastTabElement }  from "../../utils";
import { filterStore } from './FilterStore';

const texts = ref<any>(uhr.localizedTexts);
const users = ref<KontoUser[]>([]);
const showModal = ref(false);

onMounted(async () => {
  if(filterStore.queryString === "") {
    filterStore.setParameter("s","Ny");
  }
  fetchAllUsers(); // start with no filter
});

const fetchAllUsers = () => {

  api.getAccountUsers(filterStore.queryString)
    .then(r => {
      users.value = r;
    });
};

watch(filterStore, () => {
  fetchAllUsers ();
})



const approveUser = (updatePerson: KontoUser) => {
  api.approveUser(updatePerson.userName).then(() => {
    fetchAllUsers();
    collapseToggle();

  });
}

const confirmRejectUser = (updatePerson: KontoUser) => {
  api.rejectUser(updatePerson.userName).then(() => {
    fetchAllUsers();
    showModal.value = false; // Close the modal
    collapseToggle();

  });
}

const cancelRejectUser = () => {
  showModal.value = false; // Close the modal
};

const checkState = (userState: String) => {
  return (userState == "Beviljad");
}


const collapseToggle = () => {
  var usersBtns = document.getElementsByClassName("accordion-button-users");
  for (var userBtn of usersBtns){
    userBtn.setAttribute("aria-expanded", "false");
    userBtn.classList.add("collapsed");
  }
  var usersPges = document.getElementsByClassName("accordion-collapse-users");
  for (var userPge of usersPges){
    userPge.setAttribute("aria-expanded", "false");
    userPge.classList.remove("show");
  }
}

</script>