<template>
    <span><RouterLink to="/">Tillbaka till forumet</RouterLink></span>
    <div>
        <div class="mainTopic d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
                <div class="fw-bold">
                    {{messagesPaginated?.topicIncludingMessage?.topic?.userDisplayName }}
                    <span class="time">{{ formatDate(messagesPaginated?.topicIncludingMessage?.topic?.posted) }}</span>
                </div>
                <h2>{{ messagesPaginated?.topicIncludingMessage?.topic?.topicName }}</h2>
                <div class="message" v-html="messagesPaginated?.topicIncludingMessage?.message.messageText"></div>
                <ul v-if="messagesPaginated?.topicIncludingMessage?.message.attachments.length" class="file-preview">
                    <li v-for="attachment in messagesPaginated?.topicIncludingMessage?.message.attachments">
                        <a :href="'/api/yaf/downloadattachment/' + attachment.id" :class="attachmentClass(attachment)">
                            <img v-if="isImage(attachment)" :src="'/api/yaf/previewattachment/' + attachment.id" class="thumb">
                            <span>{{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                        </a>
                    </li>
                </ul>

            </div>
        </div>
        <CreateMessage :replyToId="replyTo" :buttonText="texts.forum.answerthread" @loadMessages="fetchMessages" />

        <ul class="list-group messages">
            <!-- Modal -->
            <EditMessage :message="currentMsg" :isVisable="showEditModal" @loadMessages="fetchMessages" @closeEditModal="cancelEditMsg"></EditMessage>
            <div v-if="showDeleteModal" class="modal-deleteMsg" 
                @keydown.tab.prevent="$event.shiftKey || !checkIfLastTabElement($event) || focusFirstElement()">
                <div class="modal-content">
                    <p>{{ texts.forum.deletetext}} </p>
                    {{ status_message }}
                    <button @click="confirmDeleteMsg(currentMsg)" class="btn btn-primary confirm" ref="modalConfirm" >{{
                    texts.forum.deleteConfirm}}</button>
                    <button @click="cancelDeleteMsg" class="btn btn-outline-primary btn-small"   >{{ texts.forum.cancel
                    }}</button>
                </div>
        </div>
            <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(item, index) in replies?.filter(i => !i.isDeleted)" :key="index">
                <div class="ms-2 me-auto">
                    <div class=" comment-header">

                        <div class="fw-bold ">
                            {{ item.userDisplayName }}
                            <span class="time" v-if="item.posted">({{ formatDate(item.posted) }})</span>
                            <span v-if="item.edited" class="badge bg-primary" style="margin-left: 1rem;"> {{ texts.forum.isedited }} </span>
                        </div>

                    </div>

                    <div v-if="isReply(item)" class="reply">
                        {{ texts.forum.answerto }} <b> {{ item.replyDisplaName }}</b> <br />
                        <span v-if="!item.replyToIsDeleted" v-html="item.replyText" ></span>
                        <em v-if="item.replyToIsDeleted">{{texts.forum.messagehasbeendeleted}}</em>
                    </div>
                    <div v-html="item.messageText"></div>

                    <ul v-if="item.attachments.length" class="file-preview">
                        <li v-for="attachment in item.attachments">
                            <a :href="'/api/yaf/downloadattachment/' + attachment.id">
                                <img v-if="isImage(attachment)" :src="'/api/yaf/previewattachment/' + attachment.id" class="thumb">
                                <span>{{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                            </a>
                        </li>
                    </ul>
                    <div>
                        <CreateMessage :replyToId="item.id" :buttonText="'Svara'" @loadMessages="fetchMessages" />
                    </div>
                </div>
                <div v-if="item.userCanEditMsg">
                    <button  class="btn btn-outline-primary btn-small" style="margin-top:0;" type="button" @click="currentMsg = item; showEditModal = true;"> {{texts.forum.editMessage}}</button> 
                    <button  class="btn btn-outline-primary btn-small" style="margin-top:0;" type="button" @click="currentMsg = item; showDeleteModal = true;"> {{ texts.forum.delete }}  </button> 
                </div>
                <span class="badge bg-primary">{{ item.position }}</span>

            </li>
        </ul>
        <div class="result-paging col-xs-12"
            v-if="messagesPaginated.paginatedList != null && messagesPaginated.paginatedList.length > 0">
            <PaginationComp :pagination="messagesPaginated.pagination" @handlePageChange="handlePageChange">
            </PaginationComp>
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch, nextTick} from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { Message, GetMessagesParams, MessagesPaginated, Attachment, DeleteMessageParams} from '../../YafTypes';
import { loadMessagesWithPagination } from '../../services/YafService';
import {deleteMessage} from '../../services/YafService';
import { focusFirstElement, checkIfLastTabElement }  from '../../utils';
import { timeAgoUntil } from "../../utils";
import  CreateMessage from './CreateMessage.vue';
import EditMessage from './EditMessage.vue';
import PaginationComp from '../Pagination.vue';

const texts = ref<any>(uhr.localizedTexts);

const router = useRouter();
const route = useRoute();
const showEditModal = ref(false);
const showDeleteModal = ref(false);

const props = defineProps({
    topicid: Number,
    heading: String
});
var currentMsg : Message = {id: 0, attachments: []};
const topicId = ref(0);
const replyTo = ref(0);
const status_message = ref('');
const modalConfirm = ref<HTMLInputElement | null>(null);
const messagesPaginated = ref<MessagesPaginated>({
    topicIncludingMessage: {message : {id: 0, attachments: []}, topic : {topicId: 0}},
    paginatedList: [],
    pagination: {
        numberOfPages: 1,
        currentPage: 1
    }
});

watch(showDeleteModal, (newValue : any) => {
    if (newValue) {
      // Modal is shown, set focus on the first button
      nextTick(() => {
        if (modalConfirm.value) {
            modalConfirm.value.focus();
        }
      });
    }
  }
);

const isReply = (m: Message) => {
    return m.replyTo && m.replyText;
}

const prettySize = (s: number) => {
    if(s > 1000000){
        return Math.round(s / 100000) / 10 + 'Mb';
    }
    if(s > 1000){
        return Math.round(s / 100) / 10 + 'kb';
    }

    return s + 'b';
}


const confirmDeleteMsg = (message?: Message) => {
    if (message != null){
        
        const deleteMessageParams: DeleteMessageParams = {
            messageId : message.id,
        };
        deleteMessage(deleteMessageParams).then(() => {
            showDeleteModal.value = false; // Close the modal
            fetchMessages();
            
        })        
        .catch(error => {
            console.error("Ett fel uppstod, kunde inte radera meddelande", error);
            status_message.value = "Failed to delete message";
        });
    }
        
}

const cancelEditMsg = () => {
    showEditModal.value = false; // Close the modal

};

const cancelDeleteMsg = () => {
    showDeleteModal.value = false; // Close the modal
    status_message.value = ""; // Reset status message
    
};

const formatDate = (d: string | undefined) => {
    if (d) {
        const date = new Date(d);
        return timeAgoUntil(date);
    }
    return '';
}

const isImage = (a: Attachment): boolean => {
    return a.contentType?.toLocaleLowerCase().startsWith('image');
}


const attachmentClass = (a: Attachment): string => {
    const x = a.contentType.toLocaleLowerCase().split('/');
    return "attachment " + x[0];
};

const replies = ref<Message[]>();

function handlePageChange(pagenumber: number) {
    router.push({
        path: '/topic/' + topicId.value + '/' + pagenumber
    });
}

async function fetchMessages() {
    const GetMessageParams: GetMessagesParams = {
                topicId: props.topicid!,
                page: messagesPaginated.value.pagination.currentPage,
                pageSize: uhr.forum.forumHits
            };

    if (topicId) {
        try {
            messagesPaginated.value = await loadMessagesWithPagination(GetMessageParams);
            replies.value = messagesPaginated.value.paginatedList;
            replyTo.value  = messagesPaginated.value.topicIncludingMessage.message?.id;
        } catch (error) {
            console.error("Failed to load messages", error);
        }
    }
}

const setPage = (page: number) => {
    messagesPaginated.value.pagination.currentPage = page;
    fetchMessages();
};

router.afterEach(x => {
   var page = parseInt(<string>x.params.page);
    if(isNaN(page) || page < 1) {
        page = 1;
    }
    setPage(page);
});

onMounted(async () => {
    topicId.value = props.topicid ? props.topicid : 0;

    var page = parseInt(<string>route.params.page);
    if(isNaN(page) || page < 1) {
        page = 1;
    }

    setPage(page);
});

</script>
<style>

 .modal-deleteMsg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    }
    .modal-content {
        width: unset;
        background-color: white;
        padding: 20px;
        border-radius: 5px;

    }
   .confirm {
        margin-bottom: 0.5rem;
        margin-right: 0;
    }
</style>