<template>
    <div class="topicForm-buttons" v-if="hideButtonDiv">
        <button v-show="showCreateButton" id="createThread" class="btn btn-outline-primary btn-small" type="button"
            @click="toggleButtons" data-bs-toggle="collapse" data-bs-target="#topicForm" aria-expanded="false"
            aria-controls="topicForm">
            Skapa tråd
        </button>
        
        <div class="collapse" id="topicForm">
            <input class="form-control topic-textinput" type="text" placeholder="Ange rubrik, max 100 tecken"
                v-model="inputTopic" :maxlength="maxLength"></input>
            <CKEditor :editorData="editorData" @update:editorData="updateEditorData" class="topic-editor" />
            <br/>
            <label for="upload-file">Filer:</label><br/>
            <input id="upload-file" type="file" ref="upload" multiple>
            <div class="create-buttons">
                <button v-show="showSaveCancelButton" id="saveThread" class="btn btn-primary btn-small" type="button"
                    @click="createNewTopic" data-bs-toggle="collapse" data-bs-target="#topicForm" aria-expanded="false"
                    aria-controls="topicForm">
                    Skapa
                </button>
                <button v-show="showSaveCancelButton" id="cancelNewThread" class="btn btn-outline-primary btn-small" @click="cancel"
                    type="button" data-bs-toggle="collapse" data-bs-target="#topicForm" aria-expanded="false"
                    aria-controls="topicForm">
                    Avbryt
                </button>
            </div>
        </div>
    </div>
    <div class="topic">
        <ul class="list-group topics">
            <li v-if="topicsPaginated.paginatedList" v-for="(item) in topicsPaginated.paginatedList">
                <div class="topic-card" v-on:click="goToTopic(item)">
                    <i class="material-icons-sharp arrow material-icons-sharp-arrow-forward-ios" role="img"></i>
                    <a class="topic-header" v-on:click="goToTopic(item)" @keydown.enter="goToTopic(item)" tabindex="0">
                        {{ item.topicName }}
                    </a>
                    <span>
                        <div v-html="item.messageText" />
                    </span>
                    <p class="topic-author fw-bold">
                        Skapad av: {{ item.userDisplayName }}
                        <span class="time" v-if="item.posted">({{ formatDate(item.posted) }})</span>
                    </p>
                </div>
            </li>
        </ul>
        <div class="result-paging col-xs-12"
            v-if="topicsPaginated.paginatedList != null && topicsPaginated.paginatedList.length > 0">
            <PaginationComp :pagination="topicsPaginated.pagination" @handlePageChange="handlePageChange">
            </PaginationComp>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Topic, GetTopicParams, PaginationResult, CreateTopicParams } from '../../YafTypes';
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted } from "vue";
import PaginationComp from '../Pagination.vue';
import { loadTopicsWithPagination, createTopic } from '../../services/YafService';
import CKEditor from './Editor.vue';
import { timeAgoUntil } from "../../utils";

const upload = ref<HTMLInputElement | null>(null);
const router = useRouter();
const route = useRoute();
const emits = defineEmits(['handlePageChange', 'hideButtons']);
const topicsPaginated = ref<PaginationResult<Topic>>({
    paginatedList: [],
    pagination: {
        numberOfPages: 1, 
        currentPage: 1
    }
});
const inputTopic = ref('');
const editorData = ref('');
const hideButtonDiv = ref(false);
const showCreateButton = ref(true);
const showSaveCancelButton = ref(false);
const forumId = ref(0);
const forumHits = ref(5);
const maxLength = ref(100); //maxlängd dbo.yaf_Topic, column Topic, nvarchar(100)

const goToTopic = (item: Topic) => {
    router.push({
        path: '/topic/' + item.topicId
    });
    emits("hideButtons", true);
};

const setPage = (page: number) => {
    topicsPaginated.value.pagination.currentPage = page;
    fetchTopics();
};

router.afterEach(x => {
   var page = parseInt(<string>x.params.page);
    if(isNaN(page) || page < 1) {
        page = 1;
    }
    setPage(page);
});

onMounted(() => {
    forumId.value = uhr.forum.forumId;
    forumHits.value = uhr.forum.forumHits;

    var page = parseInt(<string>route.params.page);
    if(isNaN(page) || page < 1) {
        page = 1;
    }

    setPage(page);
    hideButtons(false);
});

async function fetchTopics() {
    if (forumId) {
        try {
            const GetTopicParams: GetTopicParams = {
                forumId: forumId.value,
                page: topicsPaginated.value.pagination.currentPage,
                pageSize: forumHits.value 
            };

            const loadedTopics = await loadTopicsWithPagination(GetTopicParams);
            topicsPaginated.value = loadedTopics;
        } catch (error) {
            console.error("Failed to load topics", error);
        }
    }
}

function handlePageChange(pagenumber: number) {
    router.push({
        path: '/' + pagenumber
    });
}

function updateEditorData(newData: string) {
    editorData.value = newData;
}

function hideButtons(hide: boolean) {
    hideButtonDiv.value = !hide;
    showCreateButton.value = true;
    showSaveCancelButton.value = false;
}

const formatDate = (d: string) => {
    if (d) {
        const date = new Date(d);
        return timeAgoUntil(date);
    }
    return '';
}

function cancel() {
    inputTopic.value = ''; // Clear the input
    updateEditorData('')
    toggleButtons();
}

async function createNewTopic() {
    const value = inputTopic.value;
    const htmlValue = editorData.value;
    const createTopicParams: CreateTopicParams = {
        forumId: forumId.value,
        topic: value,
        description: "",
        message: htmlValue
    };

    await createTopic(createTopicParams, upload.value)
        .then(() => {
            inputTopic.value = ''; // Clear the input after sending
            fetchTopics();
            updateEditorData('');
        })
        .catch(error => {
            console.error("Ett fel uppstod, kunde inte skapa nytt ämne", error);
        });
    toggleButtons();
}

function toggleButtons() {
    showCreateButton.value = !showCreateButton.value;
    showSaveCancelButton.value = !showSaveCancelButton.value;
}
</script>

<style>

</style>