<template>
    <div>
        <label :for="id">{{filterData.labelText}}</label>

        <select v-model="selectedValue" class="btn btn-filter dropdown-toggle" :class="className" v-if="filterData.options.length > 0"
            @change="newSelection" :id="id">
        
            <option v-for="option in filterData.options" :value="option.value" :key="option.value">
                {{ option.label }}
            </option>
        </select>
        <div v-else>Error: finns ingen {{ filterData.label }}data att visa</div>
    </div>
</template>

<script setup lang="ts">
import { onMounted  } from 'vue';
import { filterStore } from './FilterStore';

defineEmits(['selection']);

const selectedValue = defineModel({ default: "" });


onMounted(() => {
    let urlParam: string|undefined = filterStore.getParameter(props.parameter);
    if (urlParam !== undefined) {
        selectedValue.value = urlParam;
    }
})


function newSelection() {
   let text: string = selectedValue.value as string;
    if (text === "" || text === undefined) {
        filterStore.removeParameter(props.parameter);
    } else {
        filterStore.setParameter(props.parameter, text);
    }
}   


const props = defineProps({
    className: { type: String, required: true, default:"dropdown-filter"},
    filterData: { type: Object, required: true, },
    parameter: { type: String, required: true,  },
    id: { type: String, required: true, },
});


</script>
