<template>
    <!-- Modal -->
    <div v-if="isVisable">

        <div  class="modal-editMsg" 
        @keydown.tab.prevent="$event.shiftKey || !checkIfLastTabElement($event) || focusFirstElement()">
            <div class="modal-content" >
                <label >{{ texts.forum.editMessageText}}</label>
                <div class="messageEditor" :id="editorName()" >
                    <CKEditor :editorData="editorData" @update:editorData="updateEditorData" class="topic-editor" />
                    <br/>
                    <label for="upload-file">{{texts.forum.files}}</label><br/>
                    <!-- Lista på filer -->
                    <ul v-if="props.message?.attachments.length" class="file-preview">
                        <li v-for="attachment in props.message?.attachments">
                            <div v-if="!filesToRemove.includes(attachment.id)" class="attachmentList">
                                <a  :href="'/api/yaf/downloadattachment/' + attachment.id">
                                    <img v-if="isImage(attachment)" :src="'/api/yaf/previewattachment/' + attachment.id" class="thumb">
                                    <span>{{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                                </a>
                                <button aria-label="Ta bort fil" class="badge btn btn-primary btn-small" type="button" @click="removeAttachment(attachment)">x</button>
                            </div>
                            <div v-if="filesToRemove.includes(attachment.id)" class="attachmentList attachmentListRemoved">
                                <span>
                                    <img v-if="isImage(attachment)" :src="'/api/yaf/previewattachment/' + attachment.id" class="thumb">
                                    <span> {{ attachment.fileName }} ({{ prettySize(attachment.bytes) }})</span>
                                </span>
                                <button class="badge btn btn-primary btn-small disabled" type="button">x</button>
                            </div>
                        </li>
                    </ul>
                    <input id="upload-file" type="file" ref="upload" multiple>
                </div>
                <button id="saveMessage" class="btn btn-primary btn-small" type="button"
                @click="sendData" data-bs-toggle="collapse" :data-bs-target="editorName(true)" aria-expanded="false"
                :aria-controls="editorName()">
                {{ texts.forum.saveBtn }}
            </button>
            <button ref="modalEdit" id="cancelEditMessage" class="btn btn-outline-primary btn-small" @click="emits('closeEditModal'); filesToRemove = []"
                type="button" data-bs-toggle="collapse" :data-bs-target="editorName(true)" aria-expanded="false"
                :aria-controls="editorName()">
                {{ texts.forum.cancel }}
            </button>
            </div>
        </div>
        <p id="statusMsg">{{ status_message }}</p> 
    </div>

</template>

<script setup lang="ts">
import {ref, PropType, watch, nextTick} from 'vue'
import CKEditor from './Editor.vue';
import { editMessage } from '../../services/YafService';
import { Message,Attachment,EditMessageParams } from "../../YafTypes";
import { focusFirstElement, checkIfLastTabElement }  from '../../utils';


const texts = ref<any>(uhr.localizedTexts);
const props = defineProps({
    message: {type: Object as PropType<Message>},
    isVisable: Boolean
});

const status_message = ref('');
const editorData = ref(props.message?.messageText);
const upload = ref<HTMLInputElement | null>(null);
const filesToRemove = ref(<Array<number>>[]);
const modalEdit = ref<HTMLInputElement | null>(null);


const emits = defineEmits(['loadMessages', 'closeEditModal']);

const editorName = (prependHash = false) => (prependHash ? '#' : '') + 'editor_' + props.message?.id + "edit";
 
function updateEditorData(newData: string) {
    editorData.value = newData;
}

watch(props, (newValue : any) => {
    if (newValue.isVisable) {
        nextTick(() => {
                modalEdit.value?.focus();
        }); 
    }
  }
);


function sendData() {
    if(props.message != null) {

        const htmlValue = editorData.value;
        const editMessageParams: EditMessageParams = {
            messageId: props.message.id,
            newMessage: htmlValue?.toString(),
            filesToRemove: filesToRemove.value,
        };
        
        editMessage(editMessageParams,upload.value)
        .then(() => {
            emits('closeEditModal');
            emits('loadMessages'); // Load new messages after sending
        })
        .catch(error => {
            console.error("Ett fel uppstod, kunde inte skapa nytt meddelande", error);
            status_message.value = "Failed to create message";
        });
        filesToRemove.value = [];   
    }
}
    
    function removeAttachment (a: Attachment) {
    filesToRemove.value.push(a.id);
}

const isImage = (a: Attachment): boolean => {
    return a.contentType?.toLocaleLowerCase().startsWith('image');
}


const prettySize = (s: number) => {
    if(s > 1000000){
        return Math.round(s / 100000) / 10 + 'Mb';
    }
    if(s > 1000){
        return Math.round(s / 100) / 10 + 'kb';
    }

    return s + 'b';
}

</script>

<style>
    .attachmentList{
        display:flex; 
        gap:1rem;
        justify-content: space-between;
    }
    .messageEditor{
        margin-top: 10px;

    }
    .topic-textinput, #topicForm {
        margin-bottom: 10px;
    }

    .btn {
        margin-top: 10px;
        margin-right: 10px;
    }

    .topicForm-buttons {
        margin-bottom: 10px;
    }

    #messageForm {
        margin-top: 10px;
    }
    .topic-editor {
        width: 48rem;
        height: 50%;

    }
    .modal-editMsg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    .modal-editMsg .modal-content {
            overflow:scroll;
            max-height: 48rem;
            max-width: 48rem;
            background-color: white;
            padding: 20px;
            border-radius: 5px;
    
        }
       .confirm {
            margin-bottom: 0.5rem;
            margin-right: 0;
        }
        .attachmentListRemoved{
            opacity: 50%;
        }
</style>