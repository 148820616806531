<template>
 
    <div class="carousel-indicators"> 
        <CarouselIndicators  :antalslides="props.ResultList.length" :activeNumber="1" :carouselId="carouselID" />
    </div>


    <div class="carousel-inner">
        <div class="carousel-item" v-for="(item, index) in props.ResultList" :class="{'active' : activeClassNumber(index) != undefined}">
            <EventListItemComponent  :itemInfo="item" :key="index" :carouselActive="activeClassNumber(index)" />
        </div>
        <button class="carousel-control-prev" type="button" :data-bs-target="carouselID" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">{{ texts.eventListItemCarouselWrapper.previous }}</span>
        </button>
        <button class="carousel-control-next" type="button" :data-bs-target="carouselID" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">{{ texts.eventListItemCarouselWrapper.next }}</span>
        </button>
    </div>


</template>

<script setup lang="ts">
    import { ref } from 'vue';

    import { EventListItem } from '../../types';
    import EventListItemComponent from './EventListItem.vue';
    import CarouselIndicators from './EventListCarouselIndicators.vue';

    const texts = ref<any>(uhr.localizedTexts);
    const carouselID = ref<string>("#liknandeEventCarousel"); 

    // retunerar bara den första som active
    // observera att activeNumber i CarouselIndicators är 1-ställd.
    // då loopen i denna component börjar på 1 
    const activeClassNumber =(i:number):number|undefined => {
        if(i == 0){
            return 0;
        }
        return undefined;
    }

    export interface Props {
        ResultList: EventListItem[];
    }
    const props = defineProps<Props>()

</script>