<template>    
  <div class="accordionquestionblock-navigation">
    <div class="header-accordionblock">       
      <h2 class="heading-block">
          {{ heading }}</h2>
      <div class="preamble-block">
          {{ preamble}}</div>  
  </div>
  <div class="search-container">
    <div class="questionsearch">
      <div class="questionsearch-searchinputgroup">
        <SearchField :placeholder="searchText" v-model="query" @keyup="searchListOnKeyUp" @search-click="doSearch" />
      </div>
    </div>
  </div>
 <div class="bottom-accordionblock">
  <div class="questionList-container">
    <div class="question-list accordion" v-if="filteredItems">
      <QuestionBlockItem v-for="(item, index) in filteredItems" :item="item" :key="index">
      </QuestionBlockItem>       
    </div>
  </div>  
    <div class="readMore-Container" v-if="visible">
          <button @click="getAllQuestionsOnButtonClick" class="readMore-button" id="">{{texts.accordionBlock.lasMer}}</button>
    </div>
 </div>
</div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import api from "../../services/SHServices";
import { QuestionItem } from "../../types";
import QuestionBlockItem from './QuestionBlockItem.vue';
import SearchField from '../SearchField.vue';
import { debounce } from '../../utils';

const props = defineProps({
  ContentID: Number
  });
 
const texts = ref<any>(uhr.localizedTexts);
const allItems = ref<Array<QuestionItem>>([]);
const filteredItems = ref<Array<QuestionItem>>([]);
const query = ref<string>();
let heading : string = "";
let preamble : string = "";
let searchText : string = "";
let visible : boolean ;


onMounted(async () => {
  api.getAccordionBlock(props.ContentID ?? 0).then(result => {
    console.log('loaded questions', result);
    heading = result.heading;
    preamble = result.preamble;
    searchText = result.searchText;
    if(result.questionListSize<=10){
      visible = false;
    }else{
      visible= true;
    }    
    allItems.value = result.questionList;
    filteredItems.value = result.questionList;
  });
});

function getAllQuestionsOnButtonClick() {
  api.getAllQuestions(props.ContentID ?? 0).then(result => {     
    allItems.value = result.questionList;
    filteredItems.value = result.questionList;
    visible= false;
  });
}
function doSearch() {
  const searchValue = query.value?.toLowerCase().trim();
  if (!searchValue) {
    filteredItems.value = allItems.value;
    return;
  }

  filteredItems.value = allItems.value.filter(item => {
    const itemName = item.heading?.toLowerCase() ?? '';
    return itemName.includes(searchValue);
  });
}

const searchListOnKeyUp = debounce(doSearch, 100);

</script>
